<template>
  <div
    v-if="$canAndRulesPass(procedure, 'compensations.plan_edit')"
    v-loading="loading"
  >


    <component
      v-if="procedureViewTemplate"
      :is="procedureViewTemplate"
      :procedure="procedure"
    ></component>


  </div>
</template>
<script>
import requestSender from "@/api/base/requestSender";
import PageHeading from "@/components/PageHeading";
import Paginator from "@/components/Paginator";
import {paginator} from "@/mixins/paginator";
import {mapGetters} from "vuex";
import {sorter} from "@/mixins/sorter";
import DeletedCardsModal from "@/components/cards/DeletedCardsModal.vue";
import CompensationProcedureCreateModal
  from "@/componentsCompany/smz/compensation/procedures/CompensationProcedureCreateModal.vue";
import CompensationProcedureEditBaseSettings
  from "@/componentsCompany/smz/compensation/procedures/CompensationProcedureEditBaseSettings.vue";

export default {
  name: 'CompensationProcedureView',
  components: {
    CompensationProcedureEditBaseSettings,
    PageHeading,
  },
  mixins: [],
  computed: {
    ...mapGetters(['user']),
    procedureViewTemplate() {
      if (!this.procedure?.type) {
        return null;
      }
      return () => import(`@/componentsCompany/smz/compensation/procedures/viewTemplates/${this.procedure.type}`);
    },
  },
  watch: {},
  data() {
    return {
      loading: false,

      procedure: {},

    }
  },
  mounted() {
    this.loadProcedure();
  },
  methods: {
    loadProcedure() {
      this.loading = true;

      requestSender('get', 'compensation-procedure/get', {
        id: parseInt(this.$route.params.procedure_id),
        expand: ['meta', 'permissions'].join(),
      })
        .then(data => {
          this.procedure = data.procedure;
        })
        .finally(() => {
          this.loading = false;
        });
    },


  }
}
</script>

<style scoped lang="scss"></style>